$(function () {

	//плавающее меню
	$(window).scroll(function () {
		var top = $(document).scrollTop();
		if (top < 40) {
			$("header").removeClass("floating");
		} else {
			$("header").addClass("floating");
		}
	});
	//слайдер каталог
	$('.produscts_sl').slick({
		centerMode: true,
		centerPadding: '0',
		speed: 1300,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: '<div class="sl-arr-left"><svg class="arr-sl" width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="arr-sl-bg" d="M60.5311 28.1413L41.468 11.0165L16.9592 19.0906L11.5495 44.3217L30.6126 61.4464L55.1214 53.3723L60.5311 28.1413Z" fill="#009CD0" fill-opacity="0"/><path class="arr-sl-border" d="M62.754 27.5575L42.3155 9.1973L16.0385 17.8539L10.2385 44.9053L30.6769 63.2655L56.954 54.6089L62.754 27.5575ZM41.5286 12.6283L59.3882 28.6719L54.3366 52.2577L31.3905 59.8387L13.5309 43.7952L18.6559 20.2051L41.5286 12.6283Z" fill="#506A90"/><path class="arr-sl-icon" d="M42.7072 37.2929C43.0977 37.6834 43.0977 38.3166 42.7072 38.7071L36.3432 45.0711C35.9527 45.4616 35.3195 45.4616 34.929 45.0711C34.5385 44.6805 34.5385 44.0474 34.929 43.6569L40.5858 38L34.929 32.3431C34.5385 31.9526 34.5385 31.3195 34.929 30.9289C35.3195 30.5384 35.9527 30.5384 36.3432 30.9289L42.7072 37.2929ZM40.0001 37H42.0001V39H40.0001V37Z" fill="#506A91"/></svg></div>',
		nextArrow: '<div class="sl-arr-right"><svg class="arr-sl" width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="arr-sl-bg" d="M60.5311 28.1413L41.468 11.0165L16.9592 19.0906L11.5495 44.3217L30.6126 61.4464L55.1214 53.3723L60.5311 28.1413Z" fill="#009CD0" fill-opacity="0"/><path class="arr-sl-border" d="M62.754 27.5575L42.3155 9.1973L16.0385 17.8539L10.2385 44.9053L30.6769 63.2655L56.954 54.6089L62.754 27.5575ZM41.5286 12.6283L59.3882 28.6719L54.3366 52.2577L31.3905 59.8387L13.5309 43.7952L18.6559 20.2051L41.5286 12.6283Z" fill="#506A90"/><path class="arr-sl-icon" d="M42.7072 37.2929C43.0977 37.6834 43.0977 38.3166 42.7072 38.7071L36.3432 45.0711C35.9527 45.4616 35.3195 45.4616 34.929 45.0711C34.5385 44.6805 34.5385 44.0474 34.929 43.6569L40.5858 38L34.929 32.3431C34.5385 31.9526 34.5385 31.3195 34.929 30.9289C35.3195 30.5384 35.9527 30.5384 36.3432 30.9289L42.7072 37.2929ZM40.0001 37H42.0001V39H40.0001V37Z" fill="#506A91"/></svg></div>',
		arrows: true,
		responsive: [
			{
				breakpoint: 380,
				settings: {
					centerMode: false,
					slidesToShow: 1
				}
			}
		]
	});
	//слайдер сертификаты
	$('.sertificat_sl').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: '<div class="sl-arr-left"><svg class="arr-sl" width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="arr-sl-bg" d="M60.5311 28.1413L41.468 11.0165L16.9592 19.0906L11.5495 44.3217L30.6126 61.4464L55.1214 53.3723L60.5311 28.1413Z" fill="#009CD0" fill-opacity="0"/><path class="arr-sl-border" d="M62.754 27.5575L42.3155 9.1973L16.0385 17.8539L10.2385 44.9053L30.6769 63.2655L56.954 54.6089L62.754 27.5575ZM41.5286 12.6283L59.3882 28.6719L54.3366 52.2577L31.3905 59.8387L13.5309 43.7952L18.6559 20.2051L41.5286 12.6283Z" fill="#506A90"/><path class="arr-sl-icon" d="M42.7072 37.2929C43.0977 37.6834 43.0977 38.3166 42.7072 38.7071L36.3432 45.0711C35.9527 45.4616 35.3195 45.4616 34.929 45.0711C34.5385 44.6805 34.5385 44.0474 34.929 43.6569L40.5858 38L34.929 32.3431C34.5385 31.9526 34.5385 31.3195 34.929 30.9289C35.3195 30.5384 35.9527 30.5384 36.3432 30.9289L42.7072 37.2929ZM40.0001 37H42.0001V39H40.0001V37Z" fill="#506A91"/></svg></div>',
		nextArrow: '<div class="sl-arr-right"><svg class="arr-sl" width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="arr-sl-bg" d="M60.5311 28.1413L41.468 11.0165L16.9592 19.0906L11.5495 44.3217L30.6126 61.4464L55.1214 53.3723L60.5311 28.1413Z" fill="#009CD0" fill-opacity="0"/><path class="arr-sl-border" d="M62.754 27.5575L42.3155 9.1973L16.0385 17.8539L10.2385 44.9053L30.6769 63.2655L56.954 54.6089L62.754 27.5575ZM41.5286 12.6283L59.3882 28.6719L54.3366 52.2577L31.3905 59.8387L13.5309 43.7952L18.6559 20.2051L41.5286 12.6283Z" fill="#506A90"/><path class="arr-sl-icon" d="M42.7072 37.2929C43.0977 37.6834 43.0977 38.3166 42.7072 38.7071L36.3432 45.0711C35.9527 45.4616 35.3195 45.4616 34.929 45.0711C34.5385 44.6805 34.5385 44.0474 34.929 43.6569L40.5858 38L34.929 32.3431C34.5385 31.9526 34.5385 31.3195 34.929 30.9289C35.3195 30.5384 35.9527 30.5384 36.3432 30.9289L42.7072 37.2929ZM40.0001 37H42.0001V39H40.0001V37Z" fill="#506A91"/></svg></div>',
		arrows: true
	});
	$('.sertificat_sl').slickLightbox({
		itemSelector: 'a.item',
		caption: 'caption'
	});
	$('.other-cards-slider').slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		prevArrow: '<div class="sl-arr-left"><svg class="arr-sl" width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="arr-sl-bg" d="M60.5311 28.1413L41.468 11.0165L16.9592 19.0906L11.5495 44.3217L30.6126 61.4464L55.1214 53.3723L60.5311 28.1413Z" fill="#009CD0" fill-opacity="0"/><path class="arr-sl-border" d="M62.754 27.5575L42.3155 9.1973L16.0385 17.8539L10.2385 44.9053L30.6769 63.2655L56.954 54.6089L62.754 27.5575ZM41.5286 12.6283L59.3882 28.6719L54.3366 52.2577L31.3905 59.8387L13.5309 43.7952L18.6559 20.2051L41.5286 12.6283Z" fill="#506A90"/><path class="arr-sl-icon" d="M42.7072 37.2929C43.0977 37.6834 43.0977 38.3166 42.7072 38.7071L36.3432 45.0711C35.9527 45.4616 35.3195 45.4616 34.929 45.0711C34.5385 44.6805 34.5385 44.0474 34.929 43.6569L40.5858 38L34.929 32.3431C34.5385 31.9526 34.5385 31.3195 34.929 30.9289C35.3195 30.5384 35.9527 30.5384 36.3432 30.9289L42.7072 37.2929ZM40.0001 37H42.0001V39H40.0001V37Z" fill="#506A91"/></svg></div>',
		nextArrow: '<div class="sl-arr-right"><svg class="arr-sl" width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="arr-sl-bg" d="M60.5311 28.1413L41.468 11.0165L16.9592 19.0906L11.5495 44.3217L30.6126 61.4464L55.1214 53.3723L60.5311 28.1413Z" fill="#009CD0" fill-opacity="0"/><path class="arr-sl-border" d="M62.754 27.5575L42.3155 9.1973L16.0385 17.8539L10.2385 44.9053L30.6769 63.2655L56.954 54.6089L62.754 27.5575ZM41.5286 12.6283L59.3882 28.6719L54.3366 52.2577L31.3905 59.8387L13.5309 43.7952L18.6559 20.2051L41.5286 12.6283Z" fill="#506A90"/><path class="arr-sl-icon" d="M42.7072 37.2929C43.0977 37.6834 43.0977 38.3166 42.7072 38.7071L36.3432 45.0711C35.9527 45.4616 35.3195 45.4616 34.929 45.0711C34.5385 44.6805 34.5385 44.0474 34.929 43.6569L40.5858 38L34.929 32.3431C34.5385 31.9526 34.5385 31.3195 34.929 30.9289C35.3195 30.5384 35.9527 30.5384 36.3432 30.9289L42.7072 37.2929ZM40.0001 37H42.0001V39H40.0001V37Z" fill="#506A91"/></svg></div>',
		arrows: true,
		responsive: [
			{
				breakpoint: 1380,
				settings: {
					slidesToShow: 3
				}
			}
		]
	});

	let linkIcon = $('#arr-right');
	$('.head-link').hover(function () {
		linkIcon.find('.arr-icon').css('fill', 'white');
		linkIcon.find('.arr-border').css('fill', '#009CD0');
		linkIcon.find('.arr-bg').css('fill', '#009CD0');
	}, function () {
		linkIcon.find('.arr-icon').css('fill', '#506A90');
		linkIcon.find('.arr-border').css('fill', '#506A90');
		linkIcon.find('.arr-bg').css('fill', '#0D0E11');
	});
//развернуть меню				
	$("header .toggle").on('click', function () {
		if ($("header").hasClass("active")) {
			$("header").removeClass("active");
			$("header .menu").slideUp();
		} else {
			$("header").addClass("active");
			$("header .menu").slideDown();
		}
	});



	$(window).on('load resize', function () {
		if ($(window).width() < 380) {
			//mobile
			$('.js-gallery a').on('click', function (e) {
				e.preventDefault();
			});
			$('.js-gallery').slick({
				arrows: false,
				slidesToShow: 1.1,
				slidesToScroll: 1
			});
		} else {
			//desktop
			(function ($) {
				$('.js-gallery').filter('.slick-initialized').slick('unslick');
			}(jQuery));

			//страница карточки товара смешение блока характеристик
			(function ($) {
				let infoHeight = $(".card_pg .wrap_card .info").eq(0).innerHeight();
				let fotoHeight = $(".card_pg .wrap_card .foto").eq(0).innerHeight();
				let itemChange = $(".card_pg .wrap_card .info").eq(1);
				let m = fotoHeight - infoHeight;
				itemChange.css("marginTop", "-" + m + "px");
			}(jQuery));

			//маска карты стр. "контакты"
			(function () {
				function n(e, t) {
					const n = document.getElementById("canvas"),
									i = n.getContext("2d");
					i.clearRect(0, 0, n.width, n.height),
									i.save(),
									(i.fillStyle = "rgba(13, 14, 15, 1)"),
									i.fillRect(0, 0, n.width, n.height),
									i.translate(t.x - (250 * e) / 1, t.y - (600 * e) / 2),
									i.scale(e / 1.2, e / 1.2),
									(i.fillStyle = "rgba(0, 0, 0, 1)"),
									(i.globalCompositeOperation = "destination-out");
					const r = new Path2D(
									"M949.554 98.4265H830.176V36.375H861.016V0H681.451V36.375H709.306V98.4265H344.705V36.375H375.544V0H195.482V36.375H223.337V98.4265H105.948L0 217.537V460.75L105.948 582H949.554L1056 460.75V218.25L949.554 98.4265Z"
									);
					i.stroke(r), i.fill(r), i.restore();
				}
				const i = document.getElementById("map"),
								r = document.getElementById("canvas");
				(r.width = i.offsetWidth), (r.height = i.offsetHeight);
				const a = {x: r.width / 2, y: r.height / 2};
				let o = 1;
				n(o, a),
								i.addEventListener("wheel", (e) => {
									const t = e.deltaY > 0 ? 1 : -1,
													i = Math.exp(0.2 * t);
									1 === t ? o < 75 && ((o *= i), n(o, a)) : o > 1 && (e.preventDefault(), (o *= i), n(o, a));
								}),
								window.addEventListener("resize", () => {
									(r.width = i.offsetWidth), (r.height = i.offsetHeight);
								});
			}());
		}
	});

	$('.js-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		closeOnContentClick: false,
		closeBtnInside: false,
		mainClass: 'mfp-with-zoom mfp-img-mobile',
		disableOn: 380,
		image: {
			verticalFit: true
		},
		gallery: {
			enabled: true
		},
		zoom: {
			enabled: true,
			duration: 300, // don't foget to change the duration also in CSS
			opener: function (element) {
				return element.find('img');
			}
		}
	});


	wow = new WOW(
					{
						boxClass: 'wow', // default
						animateClass: 'animated', // default
						offset: 200, // default
						mobile: false, // default
						live: true        // default
					}
	)
	wow.init();

	var ua = navigator.userAgent.toLowerCase();
	if (ua.indexOf('safari') != -1) {
		if (ua.indexOf('chrome') > -1) {
			// Chrome
		} else {
			// Safari
			$(".mobile-map").attr('href', 'maps://?q=56.839241,53.160885');
		}
	}











	//==============all==============
	//развернуть меню
	$(".js-header_active").click(function () {
		if ($(this).hasClass("active")) {
			$("header").removeClass("active");
			$(this).removeClass("active");
		} else {
			$("header").addClass("active");
			$(this).addClass("active");
		}
	});

	//кнопка вверх
	$('.js-up').click(function () {
		$('html, body').animate({scrollTop: 0}, '300');
	});
	//popup
	$(".js-modal").magnificPopup({
		removalDelay: 500,
		mainClass: 'mfp-fade',
		fixedContentPos: true
						/*callbacks: {
						 beforeOpen: function () {
						 $('body').css('overflow', 'hidden');
						 },
						 beforeClose: function () {
						 $('body').css('overflow', 'auto');
						 }
						 }*/
	});
	//табы
	$(".wrap_tabs").lightTabs();
	//маска телефона
	$(function ($) {
		$(".js-phone").mask("(999) 999-9999");
	});
	// \==============all==============

	//==============sidebar стр. новостей, faq, о компании, услуги==============
	//нижнее мобильное меню
	$(".sidebar_mobile").on('click', function () {
		var sidebar = $(this).siblings(".sidebar");
		var close = $(this).siblings(".sidebar_close")
		if (sidebar.is(":hidden")) {
			sidebar.slideDown();
			close.show();
		} else {
			sidebar.slideUp();
			close.hide();
		}
	});
	$(".sidebar_close").on('click', function () {
		$(this).siblings(".sidebar").slideUp();
		$(this).hide();
	});
	// \==============sidebar==============

	//==============стр. главная==============
	//слайдер типы световодов
	let timeBg = 900;
	let timeFront = 2000;
	let timeLights = 600;
	$('.js-scheme-sl').slick({
		infinite: false,
		speed: timeFront,
		slidesToShow: 3,
		centerMode: true,
		variableWidth: true,
		initialSlide: 1,
		touchMove: false,
		swipe: false
	});
	$('.js-bg-for').slick({
		infinite: false,
		speed: timeBg,
		slidesToShow: 3,
		centerMode: true,
		variableWidth: true,
		initialSlide: 1,
		touchMove: false,
		swipe: false
	});
	var disabledClick = false;
	$('.js-scheme-nav a[data-slide]').click(function (e) {
		e.preventDefault();
		e.stopPropagation();
		if ($(this).parent('li').hasClass('active')) {
			return false;
		}
		// Если нажатие заблокировано
		if (disabledClick === true) {
			return false;
		}
		// Блокируем нажание
		disabledClick = true;
		// Разблокируем нажатие когда анимация (предположительно) должна завершится
		setTimeout(function () {
			disabledClick = false;
		}, timeFront + timeLights * 2);

		let activeSlide = $('.js-scheme-nav li.active a[data-slide]').data('slide');
		$('.js-scheme-nav li').removeClass('active');
		$(this).parent('li').addClass('active');
		let slideno = $(this).data('slide');

		let presLights = lightItem(activeSlide);
		let lights = lightItem(slideno);
		presLights.hide(timeLights, function () {
			$('.js-scheme-sl').slick('slickGoTo', slideno - 1);
			$('.js-bg-for').slick('slickGoTo', slideno - 1);
		});
		lights.hide();
		setTimeout(function () {
			lights.show(timeLights);
		}, timeFront + timeLights)
	});
	//получение "лучей" из обьекта изображения
	function lightItem(number) {
		let object = document.querySelector("#sheme_" + number / 2).contentDocument;
		let lights = $(object).find('.lights').find('.sunbeam');
		return lights;
	}

	//подстветка подсказок на табах
	let objectSvg = {};
	$('.svg_slide').on('load', function () {
		let svgId = $(this).attr('id');
		let domSvg = document.querySelector("#" + svgId).contentDocument;
		$(domSvg).find('.color').css('stroke', "#000");
		objectSvg[svgId] = domSvg;
	});
	let itemHover = '';
	//подсветка svg
	$('.help').hover(function () {
		let svgId = $(this).siblings('.svg_slide').attr('id');
		let cssClass = this.classList[1];
		itemHover = $(objectSvg[svgId]).find('.' + cssClass).find('.color');
		itemHover.css({
			transition: "0.3s",
			stroke: "#00AEB3",
			strokeWidth: 2
		});
	},
					function () {
						itemHover.css({
							stroke: "#000",
							strokeWidth: 1
						});
					});

	//проекты
	$("section.projects .item:not(.main)").on('click', function () {
		let main = $("section.projects .item.main");
		let item = $(this);

		main.addClass('anim');
		item.addClass('anim');

		setTimeout(function () {
			/*let mHtml = main.html();
			 let html = item.html();
			 
			 item.html(mHtml);
			 main.html(html);*/

			let mainImg = main.find('img').attr('src');
			let mainHead = main.find('h3').html();
			let mainText = main.find('p').html();
			let mainNum = main.find('.num').html();

			let img = item.find('img').attr('src');
			let head = item.find('h3').html();
			let text = item.find('p').html();
			let num = item.find('.num').html();

			item.find('img').attr('src', mainImg);
			item.find('h3').html(mainHead);
			item.find('p').html(mainText);
			item.find('.num').html(mainNum);
			main.find('img').attr('src', img);
			main.find('h3').html(head);
			main.find('p').html(text);
			main.find('.num').html(num);

			item.removeClass('anim');
			main.removeClass('anim');
		}, 500);
	});

	// \==============стр. главная==============

	// ============== стр. portfel-detail==============

	$('.photo-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Загрузка изображения #%curr%...',
		zoom: {
			enabled: true,
			duration: 300 // продолжительность анимации. Не меняйте данный параметр также и в CSS
		},
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
		}
	});
	$('.video-gallery').magnificPopup({
		delegate: 'a',
		type: 'iframe',
		tLoading: 'Загрузка изображения #%curr%...',
		zoom: {
			enabled: true,
			duration: 300 // продолжительность анимации. Не меняйте данный параметр также и в CSS
		},
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
		}
	});
	//слайдер фото отбьекта на мобильном
	$(window).on('load resize', function () {
		if ($(window).width() < 380) {
			$('.js-wrap_foto').slick({
				arrows: false,
				slidesToShow: 1.1,
				slidesToScroll: 1
			});
		} else {
			$(".js-wrap_foto").slick("unslick");
		}
	});


	$('.js-sl_feedback .foto a').magnificPopup({
		type: 'image',
		zoom: {
			enabled: true,
			duration: 300 // продолжительность анимации. Не меняйте данный параметр также и в CSS
		}
	});

	//кнопка "показать больше"
	$(".js-show_more").on('click', function () {
		var foto = $(this).siblings('.foto:nth-child(n+9)');
		var nameButton = $(this).closest('.js-wrap_foto').attr('data-name-tab');
		if (foto.is(':hidden')) {
			foto.slideDown();
			$(this).text('Скрыть');
			$(this).toggleClass('active');
		} else {
			foto.slideUp();
			$(this).text('Все ' + nameButton + '');
			$(this).toggleClass('active');
		}
	});

	//слайдер "Отзыв клиента"
	$('.js-sl_feedback').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: '<div class="sl-arr-left"><img src="img/icons/arr-left-green.png" alt=""></div>',
		nextArrow: '<div class="sl-arr-right"><img src="img/icons/arr-right-green.png" alt=""></div>',
		arrows: true,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					arrows: false
				}
			}
		]
	});
	// \============== стр. portfel-detail==============

	// ============== стр. главная, portfel-detail==============
	//слайдер главный сверху
	$('.js-main_slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		prevArrow: '<div class="sl-arr-left"><img src="img/icons/arr-left.png" alt=""></div>',
		nextArrow: '<div class="sl-arr-right"><img src="img/icons/arr-right.png" alt=""></div>',
		arrows: true,
		dots: true
	});
	// \============== стр. главная, portfel-detail==============

	// ============== стр. portfel, portfel-detail, catalog-detail==============
	//слайдер "Какое оборудование применялись на объекте"
	$('.js-sl_eq').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: '<div class="sl-arr-left"><img src="img/icons/arr-left.png" alt=""></div>',
		nextArrow: '<div class="sl-arr-right"><img src="img/icons/arr-right.png" alt=""></div>',
		arrows: true,
		infinite: true,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 2.5,
					arrows: false
				}
			},
			{
				breakpoint: 420,
				settings: {
					slidesToShow: 1.1,
					arrows: false
				}
			}
		]
	});
	// \============== стр. portfel, portfel-detail, catalog-detail==============

	//=============калькулятор стр. главная, каталог-детаил=============
	$('#calc-object').on('load', function () {
		var object = document.querySelector("#calc-object").contentDocument;
		var item = $(object).find('g[id^=sec] .field');
		var light = $(object).find('#calc-light');
		var arrIdS = [];

		//Собираем массив с нужными ид
		$(object).find('g').each(function () {
			let detId;
			detId = $(this).attr('id');
			if (m = detId.match(/^sec-(\d+)$/)) {
				arrIdS.push(m[0]);
			}
		});

		//подсветка svg
		item.hover(function () {
			$(this).siblings('path').css({
				transition: "0.3s",
				stroke: "#00AEB3",
				strokeWidth: 2
			});
		},
						function () {
							$(this).siblings('path').css({
								stroke: "#F7701D",
								strokeWidth: 1
							});
						});
		//модальное окно по клику
		$.each(arrIdS, function (index, value) {
			$(object).find('#' + value + '').magnificPopup({
				removalDelay: 500,
				mainClass: 'mfp-fade',
				items: {
					src: '#h-calc',
					type: 'inline'
				},
				callbacks: {
					open: function () {
						//Запись названия шахты в модальное окно "Длина шахты"
						$('#h-calc input[name="mine"]').attr('title', $(object).find('#' + value + '').attr('data-name'));
					},
					close: function () {
						//console.log('close');
					}
				}
			});
		});

		//кео подстветка
		$('#keo').on('input', function () {
			var keo = $(this).val();
			var res = (keo / 0.1 * 0.02);
			light.css('opacity', res);
		});

	});

	//Кнопка плюс для модального окна "Длина шахты"
	$('#h-calc .plus').on('click', function () {
		let i, sum = 0.8, step = 0.2;
		i = $(this).siblings('input[name="mine"]');
		if (i.val() != '')
			sum = step + parseFloat(i.val());
		i.val(sum.toFixed(1));
	});

	//Кнопка минус для модального окна "Длина шахты"
	$('#h-calc .minus').on('click', function () {
		let i, sum = 0.8, step = 0.2;
		i = $(this).siblings('input[name="mine"]');
		if (i.val() != '') {
			if (parseFloat(i.val()) > sum) {
				sum = parseFloat(i.val()) - step;
			} else {
				sum = parseFloat(i.val());
			}
		}
		i.val(sum.toFixed(1));
	});

	//Кнопка подтверждения для модального окна "Длина шахты"
	$('#h-calc .btn').on('click', function (e) {
		e.preventDefault();
		let elem = $(this).siblings('.count').find('input[name="mine"]');
		let elemName = $(this).siblings('.count').find('input[name="mine"]').attr('title');
		$('input[name="mine-res"]').val(elem.val());
		$('input[name="mine-res"]').attr('title', elemName);
		$.magnificPopup.close();
	});

	$('.js-modal-home').on('click', function (e) {
		e.preventDefault();
		let f = $('#calc-homepage'), objData = {};
		objData = formCheck(f);
		if (!objData.success) {
			$('#h-err .desc').html(objData.err);
			$.magnificPopup.open({
				removalDelay: 500,
				mainClass: 'mfp-fade',
				items: {
					src: '#h-err',
					type: 'inline'
				},
			});
		} else {
			$.ajax({
				url: "/inc/pick.php",
				type: "POST",
				dataType: "json",
				data: objData,
				success: function (data) {
					if (data.success) {
						$('#popup-calc ul li').remove();
						$('#popup-calc ul').html(data.description);
						$('#popup-calc input[name="list-product"]').val(data.list);
					} else {
						$('#popup-calc ul').html('Ошибка в расчете');
					}
				}
			});

			$.magnificPopup.open({
				removalDelay: 500,
				mainClass: 'mfp-fade',
				items: {
					src: '#popup-calc',
					type: 'inline'
				},
			});
		}
	});

	// Отправка модальной формы на расчете формы калькулятора
	$('#calc-modal-form').on('submit', function (e) {
		e.preventDefault();

		let f = $('#calc-modal-form'), f2 = $('#calc-homepage'), objData = {};
		objData = Object.assign({}, formCheck(f2), formCheck(f));
		if (objData.success) {
			$.ajax({
				url: "/inc/ajax_sg.php",
				type: "POST",
				dataType: "json",
				data: objData,
				success: function (data) {
					if (data.success) {
						$.magnificPopup.open({
							removalDelay: 500,
							mainClass: 'mfp-fade',
							items: {
								src: '#h-succ',
								type: 'inline'
							},
						});
						f.find('input').each(function () {
							if ($(this).attr('name') == 'name' || $(this).attr('name') == 'phone' || $(this).attr('name') == 'email') {
								$(this).val('');
							}
						});
					} else {
						$('#h-err .head').html('Ошибка отправки формы.');
						$('#h-err .desc').html('Попробуйте через 5 минут.');
						$.magnificPopup.open({
							removalDelay: 500,
							mainClass: 'mfp-fade',
							items: {
								src: '#h-err',
								type: 'inline'
							},
						});
					}
				}
			});
		}
	});
	// \Отправка модальной формы на расчете формы калькулятора

	function isEmptyObject(obj) {
		for (var i in obj) {
			if (obj.hasOwnProperty(i)) {
				return false;
			}
		}
		return true;
	}


	//Проверка полей формы калькулятора
	function fieldСheck(e) {
		let data = e, eData = e.val(), typeData = e.attr('data-type'), resault = {}, valid = {};
		resault.type = typeData, resault.name = e.attr('name');
		valid.phone = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
		valid.email = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;

		//if (e.attr('data-req') == 'true') {
		if (eData != '') {
			switch (typeData) {
				case 'int':
					if (parseInt(eData) > 0 || e.attr('data-req') != 'true') {
						resault.val = parseInt(eData), resault.success = true;
					} else {
						resault.err = e.attr('name-rus') + ' не может быть равна нулю' + '<br/>';
					}
					break;
				case 'float':
					if (parseFloat(eData) > 0 || e.attr('data-req') != 'true') {
						resault.val = parseFloat(eData), resault.success = true;
					} else {
						resault.err = e.attr('name-rus') + ' не может быть равна нулю' + '<br/>';
					}
					break;
				case 'phone':
					if (valid.phone.test(eData) || e.attr('data-req') != 'true') {
						resault.val = eData, resault.success = true;
					} else {
						resault.err = e.attr('name-rus') + ' неправильно заполнено поле' + '<br/>';
					}
					break;
				case 'email':
					if (valid.email.test(eData) || e.attr('data-req') != 'true') {
						resault.val = eData, resault.success = true;
					} else {
						resault.err = e.attr('name-rus') + ' неправильно заполнено поле' + '<br/>';
					}
					break;
				case 'checkbox':
					if (e.prop('checked')) {
						resault.val = e.attr('name-rus'), resault.success = true;
					} else if (e.attr('data-req') == 'true') {
						resault.err = e.attr('name-rus') + ' не отмечено поле' + '<br/>';
					} else {
						return false;
					}
					break;
				default:
					resault.val = String(eData), resault.success = true;
			}
		} else {
			resault.success = false;
			resault.err = 'Незаполненное поле ' + e.attr('name-rus') + '<br/>';
		}
		//}

		return resault;
	}
	// \Проверка полей

	// Проверка формы калькулятора
	function formCheck(f) {
		let objData = {}, i = 0, name = '';
		objData.success = true;
		objData.err = '';
		f.find('input, textearea, select').each(function () {
			if (fieldСheck($(this))) {
				name = $(this).attr('name');
				objData[name] = fieldСheck($(this));
				if (!objData[name].success) {
					objData.err += objData[name].err;
					objData.success = false;
					switch (objData[name].name) {
						case 'city':
						case 'building':
							$(this).siblings('.select-selected').addClass('err-b');
							break;
						case 'length':
						case 'width':
						case 'height':
						case 'name':
						case 'phone':
						case 'email':
							$(this).addClass('err-b');
							break;
						case 'mine-res':
							$(this).closest('.wrap').find('.img').addClass('err-b');
							break;
					}
				}
				i++;
			}
		});

		return objData;
	}
	// \Проверка формы

	// Проврка полей на изменения
	$('#h-calc .btn').on('click', function () {
		let inp = $('input[name="mine-res"]').val();
		if (inp != '' && $('.calculator .img').hasClass('err-b')) {
			$('.calculator .img').removeClass('err-b');
		}
	});

	$('#calc-homepage input').on('change', function () {
		if ($(this).val() != '' && $(this).hasClass('err-b')) {
			$(this).removeClass('err-b');
		}
	});

	$('#calc-homepage .select-items div').on('click', function () {
		let sel = $(this).closest('.custom-select').find('select').val();
		let actElem = $(this).closest('.custom-select').find('.select-selected');
		if (sel != '' && actElem.hasClass('err-b')) {
			actElem.removeClass('err-b');
		}
	});
	// \Проврка полей на изменения
	// \=============калькулятор стр. главная, каталог-детаил=============

	//=============форма анкеты стр. анкета=============
	$('#anketa_form_btn').on('click', function (e) {
		e.preventDefault();

		//проверка обязательных полей
		let errors = 0;
		//текстовые поля
		let fields = $('.anketa_form').find('input[type=text]').not('[data-name=mail]');
		fields.each(function () {
			let inputText = $(this).val();
			if (inputText.length < 3) {
				$(this).addClass("error");
				errors++;
			} else {
				$(this).addClass("valid");
			}
		});
		//проверка мейл
		let mail = $('.anketa_form').find('[data-name=mail]');
		let tplMail = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
		if (tplMail.test(mail.val()) === false || mail.val() < 3) {
			mail.addClass("error");
			errors++;
		} else {
			mail.addClass("valid");
		}
		//проверка input radio
		let radioGroup = $('.anketa_form').children('.wrap_radio').find('.input_group');
		radioGroup.each(function () {
			let check = $(this).find('input:checked');
			if (check.length === 0) {
				$(this).addClass("error");
				errors++;
			}
		});

		if (errors === 0) {
			//копирование полей в popup
			let inputsData = {};
			//добавление в объект текстовых полей формы
			let inputs = $('.anketa_form').children('.wrap_inputs').find('[data-name]');
			inputs.each(function () {
				let name = $(this).data('name');
				let val = $(this).val();
				inputsData[name] = val;
			});
			//добавление в объект input radio формы
			let radio = $('.anketa_form').children('.wrap_radio').find('input[data-name]:checked');
			let radioName = radio.data('name');
			let radioValue = radio.next('label').text();
			inputsData[radioName] = radioValue;
			//имена файлов формы
			let files = $('.anketa_form').children('.wrap_files').find('span.name');
			let listFiles = $('#popup-form-confirm').find('.wrap_file_list');
			let filesCount = 0;
			files.each(function () {
				let fileName = $(this).text();
				if (fileName !== 'Название документа.pdf') {
					listFiles.append('<div class="filename">' + fileName + '</div>');
					filesCount++;
				}
			});
			if (filesCount === 0) {
				listFiles.append('<div class="filename">Прикрепленных файлов нет</div>');
			}

			//заполнение формы подтверждения
			let formConfirm = $('#popup-form-confirm');
			formConfirm.find('.js-form-name').text(inputsData.name);
			formConfirm.find('.js-form-mail').text(inputsData.mail);
			formConfirm.find('.js-form-phone').text(inputsData.phone);
			formConfirm.find('.js-form-org').text(inputsData.org + ', ' + inputsData.city);
			formConfirm.find('.request_val').text(inputsData.request);
			if (inputsData.comment !== "") {
				formConfirm.find('.comment').text(inputsData.comment);
				formConfirm.find('.comment_block').css('display', 'block');
			} else {
				formConfirm.find('.comment_block').css('display', 'none');
			}



			$.magnificPopup.open({
				removalDelay: 500,
				mainClass: 'mfp-fade',
				items: {
					src: '#popup-form-confirm',
					type: 'inline'
				}
			});
		}
	});
	//удаления css класса при изменении поля	
	$('.anketa_form').find('input[type=text]').on('change', function () {
		if ($(this).val().length > 2) {
			$(this).removeClass("error");
		}
	});
	$('.anketa_form .wrap_radio label').on('click', function () {
		$(this).parent('.radio').parent('.input_group').removeClass('error');
	});

	// \=============форма анкеты стр. анкета=============

	//=============страница документы спойлер=============
	$('.doc_item .head').on('click', function () {
		var doc_item = $(this).parents('.doc_item');
		var body = $(this).siblings('.wrap_body');
		if (doc_item.hasClass('active')) {
			body.slideUp();
			doc_item.removeClass('active');
		} else {
			doc_item.addClass('active');
			body.slideDown(/*{
			 start: function () {
			 $(this).css({
			 display: "flex"
			 });
			 }
			 }*/);
		}
	});
	// \=============страница документы спойлер=============




	// \=============бэкграунд стр. "о компании"=============
	$(window).on('load resize', function () {
		let elem = $('.company_pg .company_projects');
		if (!elem.length)
			return false;
		let topElem = elem.offset().top;
		let heightElem = elem.innerHeight();
		let sum = topElem + heightElem;
		let box = elem.parents('body');
		box.css({
			background: 'linear-gradient(to bottom, #f0f0f0 0% ' + topElem + 'px, #fff ' + topElem + 'px ' + sum + 'px, #f0f0f0 ' + sum + 'px 100%)'
		});
	});
	// \=============бэкграунд стр. "о компании"=============
});


//табы(вкладки)
(function ($) {
	jQuery.fn.lightTabs = function (options) {

		var createTabs = function () {
			tabs = this;
			i = 0;

			showPage = function (i) {
				$(tabs).children(".tabs_content").children(".item").css('height', 0);
				$(tabs).children(".tabs_content").children(".item").eq(i).css('height', 'auto');
				$(tabs).children("ul").children("li").removeClass("active");
				$(tabs).children("ul").children("li").eq(i).addClass("active");
			}

			showPage(0);

			$(tabs).children("ul").children("li").each(function (index, element) {
				$(element).attr("data-page", i);
				i++;
			});

			$(tabs).children("ul").children("li").click(function () {
				showPage(parseInt($(this).attr("data-page")));
			});
		};
		return this.each(createTabs);
	};
})(jQuery);

// ============== стр. услуги, услуги-детайл, анкета==============
//кастомный input file
var inputs = document.querySelectorAll('.inputfile');
Array.prototype.forEach.call(inputs, function (input) {
	var label = input.nextElementSibling,
					labelVal = label.innerHTML;
	input.addEventListener('change', function (e) {
		var fileName = '';
		if (this.files && this.files.length > 1)
			fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
		else
			fileName = e.target.value.split('\\').pop();
		if (fileName)
			label.querySelector('span.name').innerHTML = fileName;
		else
			label.innerHTML = labelVal;
	});
});
// \============== стр. услуги, услуги-детайл, анкета==============



$(window).on('load resize', function () {
	if ($(window).width() < 380) {
		//mobile

	} else {
		//desktop
		(function () {
//искры в футере
			const canvas = document.querySelector('#canvas_footer');
			if (!canvas)
				return false;
			const context = canvas.getContext('2d');
			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;

			requestAnimationFrame = requestAnimationFrame || webkitRequestAnimationFrame;


			const OPTIONS = {
				AMOUNT: 100,
				UPPER_LIMIT: 10,
				LOWER_LIMIT: 1};


			const UPPER_SIZE = 10;
			const LOWER_SIZE = 4;

			const doIt = () => Math.random() > 0.5;
			const update = (p) =>
				doIt() ?
								Math.max(OPTIONS.LOWER_LIMIT, p - 1) :
								Math.min(p + 1, OPTIONS.UPPER_LIMIT);
			const reset = p => {
				p.x = p.startX;
				p.y = p.startY;
			};
			const floored = r => Math.floor(Math.random() * r);
			const genParticles = () =>
				new Array(OPTIONS.AMOUNT).fill().map(p => {
					const size = floored(UPPER_SIZE) + LOWER_SIZE;
					const c = document.createElement('canvas');
					const ctx = c.getContext('2d');
					const r = Math.PI / 180 * floored(360);
					const color = `rgba(255,${100 +
									Math.floor(Math.random() * 70)}, 0, ${Math.random()})`;
					const xDelayed = doIt();
					const startX = xDelayed ?
									-(size + floored(canvas.width)) :
									floored(canvas.width * 0.25);
					const startY = xDelayed ?
									size + floored(canvas.height * 0.25) + Math.floor(canvas.height * 0.75) :
									canvas.height + size + floored(canvas.height);
					c.height = size;
					c.width = size;
					context.globalCompositeOperation = 'multiply';
					// ctx.filter = `blur(${Math.random() * size}px)`
					ctx.translate(size / 2, size / 2);
					ctx.rotate(r);
					ctx.translate(-(size / 2), -(size / 2));
					ctx.fillStyle = color;
					ctx.fillRect(0, 0, size, size);
					return {
						x: startX,
						y: startY,
						startY,
						startX,
						c,
						r,
						vx: floored(OPTIONS.UPPER_LIMIT / 4),
						vy: floored(OPTIONS.UPPER_LIMIT / 4),
						size};

				});

			let particles = genParticles();
			let FRAME_COUNT = 0;

			const draw = () => {
				if (
								canvas.width !== window.innerWidth ||
								canvas.height !== window.innerHeight)
				{
					canvas.width = window.innerWidth;
					canvas.height = window.innerHeight;
					particles = genParticles();
				}
				// context.restore()
				for (const particle of particles) {
					context.clearRect(particle.x, particle.y, particle.size, particle.size);
					FRAME_COUNT++;
					if (particle.y < canvas.height || particle.startX < 0)
						particle.x += particle.vx;
					if (particle.x > 0 || particle.startY > canvas.height)
						particle.y -= particle.vy;
					if (FRAME_COUNT % 11 === 0 && doIt())
						particle.vx = update(particle.vx);
					if (FRAME_COUNT % 13 === 0 && doIt())
						particle.vy = update(particle.vy);
					context.drawImage(particle.c, particle.x, particle.y);
					if (particle.x > canvas.width || particle.y < -particle.size)
						reset(particle);
				}
				requestAnimationFrame(draw);
			};
			requestAnimationFrame(draw);
		}());
	}
});